import { createStore, applyMiddleware, compose } from "@reduxjs/toolkit"
import reducer from "../reducers/index"
import { validateCoordinatesMiddleware } from "../middleware/uploadMiddleware"

import thunk from "redux-thunk"

const initialState = {}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, validateCoordinatesMiddleware))
)

export default store
