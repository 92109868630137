// Redux Action types
// ------------------

export const SET_FILE_PROCESS_TYPE = "SET_FILE_PROCESS_TYPE"
export const SET_FILE_SELECTION_INFO = "SET_FILE_SELECTION_INFO"
export const SET_ARCHETYPES = "SET_ARCHETYPES"
export const SET_ASSETS_ARE_THE_SAME_ARCHETYPE = "SET_ASSETS_ARE_THE_SAME_ARCHETYPE"
export const SET_SELECTED_ARCHETYPE_SERVICE = "SET_SELECTED_ARCHETYPE_SERVICE"
export const SET_SELECTED_ARCHETYPE_ASSET = "SET_SELECTED_ARCHETYPE_ASSET"
export const SET_FILE_ARCHETYPE_FIELDS = "SET_FILE_ARCHETYPE_FIELDS"
export const CLEAR_FILE_SELECTION_INFO = "CLEAR_FILE_SELECTION_INFO"
export const SET_FILE_IGNORE_ROWS = "SET_FILE_IGNORE_ROWS"
export const SET_FILE_CLIENT_HEADER_ROW = "SET_FILE_CLIENT_HEADER_ROW"
export const SET_FILE_HEADERS = "SET_FILE_HEADERS"
export const SET_CUSTOM_ASSET_PROP_HEADERS = "SET_CUSTOM_ASSET_PROP_HEADERS"
export const SET_READY_TO_PROCESS = "SET_READY_TO_PROCESS"
export const SET_COORDS_ARE_PRESENT = "SET_COORDS_ARE_PRESENT"
export const SET_FILES_PROCESSING = "SET_FILES_PROCESSING"
export const SET_PROCESSING_RESULT = "SET_PROCESSING_RESULT"
export const SET_FULL_DATA = "SET_FULL_DATA"
export const CLEAR_OOB_DATA = "CLEAR_OOB_DATA"
export const SET_OOB_DATA = "SET_OOB_DATA"

// Redux Action creators
// ---------------------

export const setFileProcessType = (name) => {
  return {
    type: SET_FILE_PROCESS_TYPE,
    name
  }
}

export const setFileSelectionInfo = (information) => {
  return {
    type: SET_FILE_SELECTION_INFO,
    information
  }
}

export const clearFileSelectionInfo = () => {
  return {
    type: CLEAR_FILE_SELECTION_INFO,
  }
}

export const setAssetsAreTheSameArchetype = (flag) => {
  return {
    type: SET_ASSETS_ARE_THE_SAME_ARCHETYPE,
    flag
  }
}

export const setArchetypes = (archetypes) => {
  return {
    type: SET_ARCHETYPES,
    archetypes
  }
}

export const setFileArchetypeService = (service) => {
  return {
    type: SET_SELECTED_ARCHETYPE_SERVICE,
    service
  }
}

export const setFileArchetypeAsset = (asset) => {
  return {
    type: SET_SELECTED_ARCHETYPE_ASSET,
    asset
  }
}

export const setFileArchetypeFields = (fields) => {
  return {
    type: SET_FILE_ARCHETYPE_FIELDS,
    fields
  }
}

export const setFileIgnoreRows = (rowCount) => {
  return {
    type: SET_FILE_IGNORE_ROWS,
    rowCount
  }
}

export const setFileClientHeaderRow = (rowCount) => {
  return {
    type: SET_FILE_CLIENT_HEADER_ROW,
    rowCount
  }
}

export const setFileHeaders = (headers) => {
  return {
    type: SET_FILE_HEADERS,
    headers
  }
}

export const setCustomAssetPropHeaders = (headers) => {
  return {
    type: SET_CUSTOM_ASSET_PROP_HEADERS,
    headers
  }
}

export const setReadyToProcess = (flag) => {
  return {
    type: SET_READY_TO_PROCESS,
    flag
  }
}

export const setCoordsArePresent = (flag) => {
  return {
    type: SET_COORDS_ARE_PRESENT,
    flag
  }
}

export const setFilesProcessing = (response) => {
  return {
    type: SET_FILES_PROCESSING,
    response
  }
}

export const setProcessingResult = (result) => {
  return {
    type: SET_PROCESSING_RESULT,
    result
  }
}

export const setFullData = (result) => {
  return {
    type: SET_FULL_DATA,
    result
  }
}

export const clearOOBData = () => {
  return {
    type: SET_OOB_DATA,
  }
}

export const setOOBData = (result) => {
  return {
    type: SET_OOB_DATA,
    result
  }
}