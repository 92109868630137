import React, { useState, useEffect, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  CircularProgress,
} from "@material-ui/core"
import { useSnackbar } from "notistack"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  timedOut: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: "75%",
  },
  hidden: {
    visibility: "hidden",
  },
  overlay: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    flexDirection: "column",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(255, 255, 255, .8)",
    zIndex: 1000,
  },
}))

export const Loading = (({ timeoutDuration = 10000 }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [timedOut, setTimedOut] = useState(false)

  useEffect(() => {
    if (!timeoutDuration) return

    const timer = setTimeout(() => {
      setTimedOut(true)
    }, timeoutDuration)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (timedOut) {
      enqueueSnackbar("This seems to be taking longer than usual. Please consider reloading page.", { variant: "error"})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timedOut])

  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  )
})

export const LoadingOverlay = ({ message }) => {
  const classes = useStyles()
  return <div className={classes.overlay}>
    <CircularProgress />
    {message && <div>{message}</div>}
  </div>
}

const LoadingScreen = ({ isLoaded, children }) => {
  return (
    <Fragment>
      {isLoaded
        ? children
        : <Loading />
      }
    </Fragment>
  )
}

export default LoadingScreen
