import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core"
import { responsiveFontSizes } from "@material-ui/core/styles"

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#C71525",
    },
    secondary: {
      main: "#343a40",
    },
  },
})

theme = responsiveFontSizes(theme)

export default theme