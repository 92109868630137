import React from "react"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MenuIcon from "@material-ui/icons/Menu"

const CollapsibleMenu = (props) => {
  const {menuItems, authenticated, handleLogout } = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon color={"primary"}/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(menuItem => (
          <MenuItem
            key={`${menuItem.name}_collaspedmenu`}
            component={Link}
            to={menuItem.route}
            onClick={handleClose}
          >
            {menuItem.name}
          </MenuItem>
        ))}
        {authenticated &&
          <div>
            <Divider />
            <MenuItem
              component={Link}
              to={"/"}
              onClick={() => {
                handleClose()
                handleLogout()
              }}
            >
            Logout
            </MenuItem>
          </div>
        }
      </Menu>
    </div>
  )
}

export default CollapsibleMenu