// The index for the redux reducer.  After an action is dispatched from
// somewhere in the client, the global state object passes first through here
// and is reduced by individual functions, which are imported here in this file,
// and manage state transitions for branches of the global state object.
// ----------------------------------------------------------------------------

import rootReducer from "./root"
import upload from "./uploadReducer"
import analyse from "./analyseReducer"
import aggregate from "./aggregateReducer"
// import report from "./reportReducer"

import { combineReducers } from "redux"

const initialState = {
  // Initialize state branches as empty objects.
  upload: {},
  analyse: {},
  aggregate: {},
  report: {},
}

const branchReducers = combineReducers({
  upload,
  analyse,
  aggregate
  // report,
})

function baseReducer(state, action) {
  // If there's no previous state, create an initial state.
  if (state === undefined)
    return initialState

  // Create a new state from the root reducer, including root-level state
  // attributes and cross-sectional transitions.
  const rootState = rootReducer(state, action)

  // Create from this new root state a state including only the branches
  // to be passed to the combined reducer.
  const branchState = {
    upload: rootState.upload,
    analyse: rootState.analyse,
    aggregate: rootState.aggregate,
    // report: rootState.report,
  }

  // Return a combination of the cross-sectional, global state reducer
  // and the domain-specific branches.
  return {
    ...rootState,
    ...branchReducers(branchState, action)
  }
}

export default baseReducer
