import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto"
  }
})

class Layout extends Component {
  render() {
    const { classes, children } = this.props

    return (
      <div className={classes.root}>
        <main className={classes.content}>{children}</main>
      </div>
    )
  }
}

export default withStyles(styles)(connect()(Layout))
