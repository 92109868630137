const BreakException = {}

export const areAggregateValuesValid = aggregateState => {
  const requiredKeys = ["selectedName", "selectedPolygon", "selectedAggAssets"]
  let isValid = true
  try {
    requiredKeys.forEach((value) => {
      if (!(value in aggregateState)) {
        isValid = false
        throw BreakException
      } else {
        // is in array, check no empty value, no null, no undefined
        return isValid = !!aggregateState[value]
      }
    })
  } catch (e) {
    if (e !== BreakException) throw e
    return isValid
  }
}

export const defaultPolygonChoices = [
  {
    value: "Global - National boundaries",
    displayName: "Global - National boundaries",
  },
  {
    value: "Australia - Suburbs (SA2)",
    displayName: "Australia - Suburbs (SA2)"
  },
  {
    value: "Australia - Regions (SA3)",
    displayName: "Australia - Regions (SA3)"
  },
]