import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { SnackbarProvider } from "notistack"
import Layout from "./components/common/Layout"
import NavHeader from "./components/common/NavHeader"
import PrivateRoute from "./components/common/PrivateRoute"
import Loading from "./components/common/Loading"
import ErrorBoundary from "./components/common/ErrorBoundary"
import ScrollToTop from "./components/common/ScrollToTop"
import "./App.css"
import theme from "./styles/theme"
import { ThemeProvider } from "@material-ui/core"

const LoginScreen = lazy(() => import("./components/login/LoginScreen"))
const Main = lazy(() => import("./components/common/Main"))
const Upload = lazy(() => import("./components/upload/Upload"))
const Assets = lazy(() => import("./components/upload/Assets"))
// const Analyse = lazy(() => import("./components/analyse/Analyse"))
// const Aggregate = lazy(() => import("./components/aggregate/Aggregate"))
// const Report = lazy(() => import("./components/report/Report"))

function App() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <ScrollToTop />
            <NavHeader />
            <Layout>
              <Suspense fallback={<Loading isLoaded={false} />}>
                <Switch>
                  <PrivateRoute path="/assets" component={Assets} />
                  <PrivateRoute path="/upload" component={Upload} />
                  {/* <PrivateRoute path="/analyse" component={Analyse} /> */}
                  {/* <PrivateRoute path="/aggregate" component={Aggregate} /> */}
                  {/* <PrivateRoute path="/report" component={Report} /> */}
                  <Route path="/login" component={LoginScreen} />
                  <PrivateRoute path="/" component={Main} />
                </Switch>
              </Suspense>
            </Layout>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default App
