// Redux Action types
// ------------------

export const CLEAR_AGGREGATE_VALUES = "CLEAR_AGGREGATE_VALUES"
export const SET_AGGREGATE_NAME = "SET_AGGREGATE_NAME"
export const SET_SELECTED_POLYGON = "SET_SELECTED_POLYGON"
export const SET_SELECTED_AGG_ASSETS = "SET_SELECTED_AGG_ASSETS"
export const SET_AGGREGATE_FILTER_TAGS = "SET_AGGREGATE_FILTER_TAGS"
export const SET_AGGREGATE_FILTER_VALUE = "SET_AGGREGATE_FILTER_VALUE"
export const SET_AGGREGATE_STATS = "SET_AGGREGATE_STATS"

// Redux Action creators
// ---------------------

export const clearAggregateValues = () => {
  return {
    type: CLEAR_AGGREGATE_VALUES
  }
}

export const setAggregateName = (name) => {
  return {
    type: SET_AGGREGATE_NAME,
    name
  }
}

export const setAggregateSelectedPolygon = (polygonId) => {
  return {
    type: SET_SELECTED_POLYGON,
    polygonId
  }
}

export const setAggregateSelectedAssets = (assets) => {
  return {
    type: SET_SELECTED_AGG_ASSETS,
    assets
  }
}

export const setAggregateFilterTags = (tags) => {
  return {
    type: SET_AGGREGATE_FILTER_TAGS,
    tags
  }
}

export const setAggregateFilterValue = (value) => {
  return {
    type: SET_AGGREGATE_FILTER_VALUE,
    value
  }
}

export const setAggregateStatsOptions = (stats) => {
  return {
    type: SET_AGGREGATE_STATS,
    stats
  }
}
