import * as actions from "../actions/aggregateActions"
import * as helpers from "../lib/aggregateHelpers"

const defaults = {
  selectedPolygon: helpers.defaultPolygonChoices[0]["value"],
  selectedAggStats: {
    basicStats: {
      displayName: "Basic stats (sum and average of damage, failure, and productivity loss risk)",
      value: false,
    },
    countOverThresholds: {
      displayName: "Count over thresholds",
      value: false,
    }
  }
}

const aggregateReducer = (state, action) => {
  if (state === undefined)
    return { ...defaults }
  switch (action.type) {
    case actions.CLEAR_AGGREGATE_VALUES:
      return {
        ...defaults
      }
    case actions.SET_AGGREGATE_NAME:
      return {
        ...state,
        selectedName: action.name
      }
    case actions.SET_SELECTED_POLYGON:
      return {
        ...state,
        selectedPolygon: action.polygonId
      }
    case actions.SET_SELECTED_AGG_ASSETS:
      return {
        ...state,
        selectedAggAssets: action.assets
      }
    case actions.SET_AGGREGATE_FILTER_TAGS:
      return {
        ...state,
        selectedFilterTags: action.tags
      }
    case actions.SET_AGGREGATE_FILTER_VALUE:
      return {
        ...state,
        selectedFilterValue: action.value
      }
    case actions.SET_AGGREGATE_STATS:
      return {
        ...state,
        selectedAggStats: action.stats
      }
    default:
      return state
  }
}

export default aggregateReducer

