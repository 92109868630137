import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { makeStyles } from "@material-ui/core/styles"
import Hidden from "@material-ui/core/Hidden"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import logo from "../../assets/logo.png"
import CollapsibleMenu from "./CollapsibleMenu"
import { doLogout } from "../../actions/loginActions"

const useStyles = makeStyles(theme => ({
  root: {
    flex: "1 0 auto",
    overflowX: "hidden",
    background: "#343a40"
  },
  title: {
    flexGrow: 0,
    color: "white",
    verticalAlign: "super",
    marginRight: theme.spacing(2),
    textDecoration: "none"
  },
  navLinksContainer: {
    display: "contents",
    overflowX: "hidden",
  },
  navLinks: {
    textDecoration: "none"
  },
  navLinkLogin: {
    float: "right",
    textDecoration: "none"
  },
  spacer: {
    flexGrow: 1
  },
  subtitle: {
    marginRight: 30,
    color: "white"
  },
  logo: {
    height: "35px",
    marginRight: theme.spacing(1)
  },
  toolbar: {
    justifyContent: "space-between"
  }
}))

const menuItems = [
  {
    name: "Assets",
    route: "/assets"
  },
  {
    name: "Upload",
    route: "/upload"
  },
  // {
  //   name: "Analyse",
  //   route: "/analyse"
  // },
  // {
  //   name: "Aggregate",
  //   route: "/aggregate"
  // },
  // {
  //   name: "Report",
  //   route: "/Report"
  // },
]

const NavHeader = (props) => {
  const classes = useStyles()
  const { authenticated, dispatch } = props
  const handleLogout = () => {
    dispatch(doLogout())
    Cookies.remove(process.env.REACT_APP_APIKEY_COOKIE_NAME)
  }

  return (
    <>
      <AppBar elevation={0} position="static" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Link className={classes.title} to={"/"}>
            <img src={logo} className={classes.logo} alt="logo" />
            <Typography display="inline" gutterBottom align="left" variant="h5" className={classes.title}>
            XDI Client Portal
            </Typography>
          </Link>

          <div className={classes.spacer}></div>

          <div className={classes.navLinksContainer}>
            {authenticated && <>
              <Hidden smDown>
                {menuItems.map(menuItem => (
                  <Link key={`${menuItem.name}_fullnav`} className={classes.navLinks} to={menuItem.route}>
                    <Typography align="left" variant="body2" className={classes.subtitle}>
                      {menuItem.name}
                    </Typography>
                  </Link>
                ))}

                <Link
                  to={"/"}
                  className={classes.navLinkLogin}
                  onClick={() => handleLogout()}
                >
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    LOGOUT
                  </Button>
                </Link>
              </Hidden>

              <Hidden mdUp>
                <CollapsibleMenu
                  menuItems={menuItems}
                  authenticated={authenticated}
                  handleLogout={handleLogout}
                />
              </Hidden>
            </>}
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

const mapStateToProps = (state) => ({
  authenticated: (state.authenticated) || false,
})

export default connect(mapStateToProps)(NavHeader)
