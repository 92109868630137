import * as actions from "../actions/uploadActions"

const defaults = {
}

const selectedFileInformationDefaults = {
  assetsAreSame: true
}

const uploadReducer = (state, action) => {
  if (state === undefined)
    return { ...defaults }
  switch (action.type) {
    case actions.SET_FILE_PROCESS_TYPE:
      return {
        ...state,
        selectedFileProcessType: action.name
      }
    case actions.CLEAR_FILE_SELECTION_INFO:
      return {
        ...state,
        selectedFileInformation: selectedFileInformationDefaults,
        fullData: [],
        OOBData: []
      }
    case actions.SET_FILE_SELECTION_INFO:
      const updatedInformation = Object.assign({}, state.selectedFileInformation, action.information)
      return {
        ...state,
        selectedFileInformation: updatedInformation
      }
    case actions.SET_ARCHETYPES:
      return {
        ...state,
        archetypes: action.archetypes
      }
    case actions.SET_ASSETS_ARE_THE_SAME_ARCHETYPE:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          assetsAreSame: action.flag
        }
      }
    case actions.SET_SELECTED_ARCHETYPE_SERVICE:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          selectedArchetypeService: action.service
        }
      }
    case actions.SET_SELECTED_ARCHETYPE_ASSET:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          selectedArchetypeAsset: action.asset
        }
      }
    case actions.SET_FILE_ARCHETYPE_FIELDS:
      const archetypeField = {
        description: "the archetype",
        label: "Archetype",
        machine: "archetype",
        type: "string",
      }
      const selectedArchetypeFields = {
        ...state.selectedFileInformation.selectedArchetypeFields,
        ...action.fields,
        fields: { ...action.fields.fields, archetype: archetypeField }
      }
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          selectedArchetypeFields,
        }
      }
    case actions.SET_FILE_IGNORE_ROWS:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          ignoreRows: action.rowCount
        }
      }
    case actions.SET_FILE_CLIENT_HEADER_ROW:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          clientHeaderRow: action.rowCount
        }
      }
    case actions.SET_FILE_HEADERS:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          headers: action.headers
        }
      }
    case actions.SET_CUSTOM_ASSET_PROP_HEADERS:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          assetPropHeaders: action.headers
        }
      }
    case actions.SET_READY_TO_PROCESS:
      return {
        ...state,
        readyToProcess: action.flag
      }
    case actions.SET_COORDS_ARE_PRESENT:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          coordsArePresent: action.flag
        }
      }
    case actions.SET_FILES_PROCESSING:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          filesProcessing: action.response.files,
          processing: action.response.status === "processing",
        }
      }
    case actions.SET_PROCESSING_RESULT:
      return {
        ...state,
        selectedFileInformation: {
          ...state.selectedFileInformation,
          processingResult: action.result
        }
      }
    case actions.SET_FULL_DATA:
      return {
        ...state,
        fullData: action.result
      }
    case actions.CLEAR_OOB_DATA:
      return {
        ...state,
        OOBData: []
      }
    case actions.SET_OOB_DATA:
      return {
        ...state,
        OOBData: action.result
      }
    default:
      return state
  }
}

export default uploadReducer
