// The root reducer responds to actions with changes to the root level
// of the global state object.  It also initializes branches and performs
// cross-sectional changes, i.e. changes across multiple branches of the state.
// ----------------------------------------------------------------------------
import * as loginActions from "../actions/loginActions"

const defaults = {
  authenticated: false
}

const rootReducer = (state, action) => {
  if (state === undefined)
    return { ...defaults }
  switch (action.type) {
    case loginActions.SET_AUTH_ACCOUNT: {
      const accountInfo = {
        id: action.account["_id"]["$oid"],
        username: action.account["username"]
      }
      return {
        ...state,
        account: accountInfo,
        authenticated: true
      }
    }
    case loginActions.DO_LOGOUT:
      return {
        ...state,
        account: null,
        authenticated: false,
      }
    default:
      return state
  }
}

export default rootReducer