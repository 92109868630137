export const fetchLogin = async (username, password) => {
  const loginUrl = `${process.env.REACT_APP_AUTH_API_BASE_URL}/v1/account?username=${username}&password=${password}`
  const result = await fetch(loginUrl, {
    method: "GET",
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
  return result
}


export const fetchLoginWithApiKey = async (apiKey) => {
  if (apiKey) {
    const loginUrl = `${process.env.REACT_APP_AUTH_API_BASE_URL}/v1/account`
    const result = await fetch(loginUrl, {
      method: "GET",
      headers: {
        Authorization: apiKey,
      },
      dataType: "json",
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
    return result
  }
}

export const fetchToken = async (username, password) => {
  const loginUrl = `${process.env.REACT_APP_AUTH_API_BASE_URL}/v1/token`
  const result = await fetch(loginUrl, {
    method: "PUT",
    headers: {
      Client: username,
      Secret: password
    },
    dataType: "json",
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
  return result
}
