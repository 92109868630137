
export const defaultOtherParams = {
  useWave: {
    displayName: "Use Wave",
    value: false,
  },
  someOtherOption: {
    displayName: "Some Other Option",
    value: false,
  }
}

export const floodDataOptions = [
  {
    value: "JBA",
    displayName: "JBA"
  },
  {
    value: "Ambiental",
    displayName: "Ambiental"
  }
]

export const scenarioOptions = [
  {
    value: "BAU",
    displayName: "Business as usual (RCP8.5, CORDEX)"
  },
  {
    value: "MA",
    displayName: "Moderate Action (RCP4.5, CORDEX)"
  },
  {
    value: "SC",
    displayName: "Steep Cuts (RCP2.6, CORDEX)"
  },
  {
    value: "NCC",
    displayName: "No Climate Change (Meehl, PCM No-GHG)"
  }
]