// Redux Action types
// ------------------

export const SET_AUTH_ACCOUNT = "SET_AUTH_ACCOUNT"
export const DO_LOGOUT = "DO_LOGOUT"

// Redux Action creators
// ---------------------

export const setAuthAccount = (account) => {
  return {
    type: SET_AUTH_ACCOUNT,
    account
  }
}

export const doLogout = () => {
  return {
    type: DO_LOGOUT,
  }
}
