import _ from "lodash"
import { clearOOBData, setOOBData, setFileSelectionInfo} from "../actions/uploadActions"

export const validateCoordinatesMiddleware = ({dispatch, getState}) => next => action => {
  // See if we have the right headers for latitude/longitude.
  // If we do, loop through and do a range check to see if any
  // are OOB. This can probably be extended for further validation
  // if required.

  if(
    action.type === "SET_FILE_HEADERS" &&
    _.find(action.headers, (v) => v === "Latitude") &&
    _.find(action.headers, (v) => v === "Longitude")
  ) {
    if(getState().upload?.selectedFileInformation?.fileProcessType === "linked") return

    const getKeyByValue = (object, value) => {
      return Object.keys(object).find(key => object[key] === value)
    }

    dispatch(clearOOBData())

    // We've already checked that we have these
    const latKey = getKeyByValue(action.headers, "Latitude")
    const longKey = getKeyByValue(action.headers, "Longitude")
    const fullData = getState().upload.fullData

    const OOBData = fullData.filter(coords => {
      const lat = Number(coords[latKey])
      const long = Number(coords[longKey])
      if((lat > 90 || lat < -90) || (long > 180 || long < -180)) return true
      return false
    })

    if(OOBData.length > 0) {
      dispatch(setOOBData(OOBData))
      // Also set our preview data to avoid doing the transforms
      // inside the component
      let initialLines = getState().upload.selectedFileInformation.initialLines
      initialLines.splice(1, 0, ...OOBData)
      // We want to display, at a minimum, ten lines of sample data. If we
      // have invalid data though, we want to show *all* of the invalid lines.
      // So, if we have *less* than ten lines of invalid data, we skim the top
      // of the valid data along with the invalid lines. If we have ten or more
      // though, we don't bother about showing the valid data and instead just
      // show all of the invalid lines.
      if(OOBData.length < 10) {
        initialLines = initialLines.slice(0, 10)
      } else {
        initialLines = initialLines.slice(0, OOBData.length)
      }
      dispatch(setFileSelectionInfo({
        ...getState().upload.selectedFileInformation,
        initialLines
      }))
    }
  }

  return next(action)
}