import * as actions from "../actions/analyseActions"
import * as helpers from "../lib/analyseHelpers"

const defaults = {
  selectedScenario: helpers.scenarioOptions[0]["value"],
  selectedFloodData: helpers.floodDataOptions[0],
  selectedOtherParameters: helpers.defaultOtherParams
}

const analyseReducer = (state, action) => {
  if (state === undefined)
    return { ...defaults }
  switch (action.type) {
    case actions.SET_SELECTED_ASSET:
      return {
        ...state,
        selectedAsset: action.asset
      }
    case actions.SET_SELECTED_SCENARIO:
      return {
        ...state,
        selectedScenario: action.scenario
      }
    case actions.SET_SELECTED_FLOOD_DATA:
      return {
        ...state,
        selectedFloodData: action.floodData
      }
    case actions.SET_SELECTED_OTHER_PARAMS:
      return {
        ...state,
        selectedOtherParameters: action.otherParams
      }
    default:
      return state
  }
}

export default analyseReducer