import React, { useEffect, useState } from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import Cookies from "js-cookie"
import Loading from "./Loading"
import { fetchLoginWithApiKey } from "../../lib/loginApi"
import { setAuthAccount } from "../../actions/loginActions"

const PrivateRoute = (props) => {
  const { component: Component, authenticated, dispatch, ...rest } = props
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!authenticated) {
      setIsLoaded(false)
      const apiKey = Cookies.get(process.env.REACT_APP_APIKEY_COOKIE_NAME)
      const token = Cookies.get(process.env.REACT_APP_TOKEN_COOKIE_NAME)
      const loginWithApi = async () => {
        try {
          const authResponse = await fetchLoginWithApiKey(apiKey)
          if (authResponse.username) {
            dispatch(setAuthAccount(authResponse))
          } else {
            if (authResponse.error && authResponse.error) {
              console.log(authResponse.error)
            } else {
              console.log("Unknown Error")
            }
          }
          setIsLoaded(true)
        } catch (e) {
          console.log("Cannot connect to authentication server. Please try again.")
        } finally {
          setIsLoaded(true)
        }
      }
      if (apiKey && token) {
        loginWithApi()
      } else {
        setIsLoaded(true)
      }
    } else {
      setIsLoaded(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authenticated])

  return (
    <Loading isLoaded={isLoaded}>
      <Route
        {...rest}
        render={rest => (
          authenticated ?
            <Component {...rest} /> :
            <Redirect to={{
              pathname: "/login",
              state: { referrer: window.location.pathname }
            }}/>
        )}
      />
    </Loading>
  )
}

const mapStateToProps = (state) => ({
  authenticated: (state.authenticated) || false,
})

export default connect(mapStateToProps)(PrivateRoute)
