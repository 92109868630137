// Redux Action types
// ------------------

export const SET_SELECTED_SCENARIO = "SET_SELECTED_SCENARIO"
export const SET_SELECTED_FLOOD_DATA = "SET_SELECTED_FLOOD_DATA"
export const SET_SELECTED_OTHER_PARAMS = "SET_SELECTED_OTHER_PARAMS"
export const SET_SELECTED_ASSET = "SET_SELECTED_ASSET"

// Redux Action creators
// ---------------------

export const setAnalyseSelectedAsset = (asset) => {
  return {
    type: SET_SELECTED_ASSET,
    asset
  }
}

export const setAnalyseSelectedScenario = (scenario) => {
  return {
    type: SET_SELECTED_SCENARIO,
    scenario
  }
}

export const setAnalyseSelectedFloodData = (floodData) => {
  return {
    type: SET_SELECTED_FLOOD_DATA,
    floodData
  }
}

export const setAnalyseSelectedOtherParameters = (otherParams) => {
  return {
    type: SET_SELECTED_OTHER_PARAMS,
    otherParams
  }
}

